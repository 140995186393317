.app-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1em;
    border-bottom: 1px solid #ddd;
}

.app-header__avatar {
    height: 48px;
    border: 2px solid #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    transition: box-shadow .5s;
}

.app-header__avatar:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
